import React from 'react'
import { Helmet } from "react-helmet"

const SEO = ({title}) => {
    return (
        <div>
            <Helmet>
                <title>{title || 'FlutterPals - Hire Top 5% Flutter Developers'}</title>
                <meta name="description" content="FlutterPals is global talent network for Flutter developers with an intensive recruitment process and distributed Flutter talent across the world." />
                {/* <meta name="og:title" property="og:title" content="Rewy - React Gatsby IT Startup Templates Collection"></meta>
                <meta name="twitter:card" content="Rewy - React Gatsby IT Startup Templates Collection"></meta> */}
                {/* <meta property="og:image" content="https://res.cloudinary.com/dev-empty/image/upload/v1593069801/explore-learning.jpg" /> */}
            </Helmet>
        </div>
    )
}

export default SEO
